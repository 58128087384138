import React from 'react'
import doc from '../img/doc.jpg'

export const Doc = () => {
    return (
        <div style={{width: "100vw", textAlign: "center"}}>
            <img src={doc} alt={""} style={{width: "80%", display: "inline-block"}}/>
        </div>
    )
}
