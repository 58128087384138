import React from 'react';
import MetaTags from 'react-meta-tags';

export class MaterialsMeta extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <MetaTags>
                    <title>Plezimet Group | Special Steels and Nickel alloys in EU</title>
                    <meta name="description"
                          content="Plezimet Group is a unique company providing metallurgic services in Europe and the CIS. We buy and sell special steels such as Inconel 718, 625, 713. AISI 304, 316. Nikel alloys for melting and much more" /><link rel="canonical" href="https://plezimet.com/es/materials/"/>
                    <meta property="og:title" content="Materials and special steels - Plezimet Group" />
                    <meta property="og:image" content="" />
                    <meta property="og:site_name" content="Plezimet Group"/>
                </MetaTags>
            </div>
        )
    }
}
