/* Created by ACSES.media {DATE: 01/10/2020} for © Plezimet Group Metallurgical Services */

import React from 'react';
import s from './stylesheets/App.module.css';
import {MainContainer} from "./containers/main-container";
import {HeaderContainer} from "./containers/header-container";
import {ContactsContainer} from "./containers/contacts-container";
import {MaterialsContainer} from "./containers/materials-container";
import {Doc} from "./components/docs";
import {Footer} from "./components/footer";
import {Redirect, Route, Switch} from "react-router";
import {MainPageMeta} from "./meta/mainMetaData";
import {ContactsPageMeta} from "./meta/ContactsMetaData";
import {MaterialsMeta} from "./meta/MaterialsMetaData";


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: this.setLanguage()
        };
        this.setLanguage.bind(this);
    }



    setLanguage = (language) => {
        if (language === undefined) {
            if (window.location.pathname.slice(1, 3).length === 0) {
                return 'es'
            } else {
                return window.location.pathname.slice(1, 3);
            }
        } else {
            let currentLocation = window.location.pathname.slice(4);
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            this.setState({
                language: language
            });

            window.location.pathname = `${language}/${currentLocation}`;
        }
    };

    setHeaderVisibleMode = (mode) => {
        this.setState({
            headerMode: mode
        })
    };

    render() {
        return (
            <div className={s.App}>
                <HeaderContainer language={this.state.language} setLanguage={this.setLanguage}/>
                <Switch>
                    <Route path={`/${this.state.language}/contacts`}>
                        <ContactsPageMeta/>
                        <ContactsContainer language={this.state.language} setHeaderMode={this.setHeaderVisibleMode}/>
                        <Footer language={this.state.language} setLanguage={this.setLanguage}/>
                    </Route>
                    <Route exact path={"/docs"}>
                        <Doc setHeaderMode={this.setHeaderVisibleMode}/>
                    </Route>
                    <Route exact path={`/${this.state.language}/materials`}>
                        <MaterialsMeta/>
                        <MaterialsContainer language={this.state.language} setHeaderMode={this.setHeaderVisibleMode}/>
                        <Footer language={this.state.language} setLanguage={this.setLanguage}/>
                    </Route>
                    <Route exact path={`/${this.state.language}`}>
                        <MainPageMeta/>
                        <MainContainer language={this.state.language} setHeaderMode={this.setHeaderVisibleMode}/>
                        <Footer language={this.state.language} setLanguage={this.setLanguage}/>
                    </Route>
                    <Redirect from="/" to={`/${this.state.language}`}/>
                </Switch>
            </div>
        );
    }
}

export default App;
