import React from 'react'
import s from '../stylesheets/main.module.css'
import {ParallaxBanner, ParallaxProvider} from "react-scroll-parallax";
import ScrollAnimation from 'react-animate-on-scroll'
import {local} from '../store/localization'
import "animate.css/animate.min.css"
import build from '../img/build.jpeg'
import cmk from '../img/cmk.png'
import cronimet from '../img/cronimet.png'
import elg from '../img/elg.png'
import elektrostal from '../img/elektrostal.png'
import gainza from '../img/gainza.jpg'
import itp from '../img/Itp.jpg'
import profmegh from '../img/profmegh.png'
import tdnikel from '../img/tdnikel.png'
import rpm from '../img/en/ruspolymet.png'
import aero from '../img/aero.png'
import baikor from '../img/baikor.png'
import favicon from '../img/favicon.png'

export const Main = (props) => {
    return (
        <ParallaxProvider>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */}
            <a id="main"/>
            <ParallaxBanner
                className={s.paralax}
                expanded={false}
                style={{
                    height: "100vh",
                }}
                layers={[
                    {
                        image: build,
                        amount: 0.1,
                        height: "100vh"
                    },
                ]}/>
            <div className={s.panel}>
                <div className={s.fadeIn}>
                    <img className={s.faviconPanel} src={favicon} alt={"favicon"}/>
                    <br/>
                    <b className={s.panelMainText}>
                        {local[props.language].banner_excerption}
                    </b>
                </div>
            </div>
            <div className={s.about}>
                <b className={s.label}>
                    {local[props.language].label_header}
                </b>
                <hr className={s.line}/>
                <p className={s.textUnderLable}>
                    {local[props.language].label_text}
                </p>
            </div>
            <div className={s.block}>
                <b className={s.label}>
                    {local[props.language].services}
                </b>
                <img src={local[props.language].img_diagram} alt={"diagram"}
                     className={s.diagram}/>
                <hr className={s.line}/>
            </div>
            <div className={s.track}>
                <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
                    <img src={local[props.language].img_track} alt={"track"}
                         style={{width: "60%", paddingTop: "10px", paddingBottom: "50px"}}/>
                </ScrollAnimation>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                <a id="ourhistory"/>
                <hr className={s.line}/>
            </div>
            <div className={s.block}>
                <b className={s.label}>
                    {local[props.language].ourhistory_label}
                </b>
                <p className={s.textUnderLable}>
                    {local[props.language].ourhistory}
                </p>
            </div>
            <div className={s.block} style={{
                marginBottom: "70px",
            }}>
                <div className={s.colums}>
                    <div className={s.luTop}>
                        <b className={s.label}>
                            {local[props.language].ourvalues}
                        </b>
                        <br/>
                        <hr className={s.line} style={{
                            width: "45%",
                            marginTop: "9px",
                            marginBottom: "20px"
                        }}/>
                        <br/>
                    </div>
                    <div className={s.lu}>
                        <b className={s.label}>
                            {local[props.language].customer}
                        </b>
                        <p className={s.textUnderLable}>
                            {local[props.language].customer_discp}
                        </p>
                        <b className={s.label}>
                            {local[props.language].environment}
                        </b>
                        <p className={s.textUnderLable}>
                            {local[props.language].environment_discp}
                        </p>
                        <b className={s.label}>
                            {local[props.language].technology}
                        </b>
                        <p className={s.textUnderLable}>
                            {local[props.language].technology_discp}
                        </p>
                        <b className={s.label}>
                            {local[props.language].result}
                        </b>
                        <p className={s.textUnderLable}>
                            {local[props.language].result_discp}
                        </p>
                    </div>
                    <div className={s.stTop}>
                        <b className={s.label}>
                            {local[props.language].ourvision_label}
                        </b>
                        <hr className={s.line} style={{
                            width: "75%",
                            marginTop: "9px",
                            marginBottom: "20px"
                        }}/>
                    </div>
                    <div className={s.st}>
                        <p className={s.textUnderLable}>
                            {local[props.language].ourvision}
                        </p>
                    </div>
                </div>
            </div>
            <div style={{width:"100%"}} className={s.block}>
                <b className={s.label}>
                    {local[props.language].friends}
                </b>
                <hr className={s.line}/>
                <div className={s.companies}>
                    <img src={aero} alt={'Aerorecycling'}/>
                    <img src={itp} alt={'Itp Aero'}/>
                    <img src={elg} alt={'elg'}/>
                    <img src={baikor} alt={'Baikor worldwide'}/>
                    <img src={elektrostal} alt={'Elektrostal'}/>
                    <img src={gainza} alt={'Gainza forge'}/>
                    <img src={cronimet} alt={'Cronimet'}/>
                    <img src={rpm} alt={'Ruspolymet'}/>
                    <img src={profmegh} alt={'ProfMeghTrans'}/>
                    <img src={cmk} alt={'CMK'}/>
                    <img src={tdnikel} alt={'TDnikel'}/>
                </div>
            </div>
        </ParallaxProvider>
    )
};
