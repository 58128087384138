import React from 'react'
import {Main} from "../components/main";

export class MainContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Main language={this.props.language}/>
        )
    }
}
