import React from 'react'
import {Contacts} from "../components/contacts";

export class ContactsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _userNameFieldValue: "",
            _companyNameFieldValue: "",
            _userEmailFieldValue: "",
            _commentFieldValue: '',
            _phoneFieldValue: '',
            _sendResult: "",
            _isContactsSent: false,
        };
        this.commentFieldHandler.bind(this);
        this.phoneFieldHandler.bind(this);
        this.sendEmail.bind(this);
        this.userEmailFieldHandler.bind(this);
        this.userNameFieldHandler.bind(this);
        this.companyNameFieldHandler.bind(this);
        this.generateDefaultTextForCommentField.bind(this);
    }

    componentDidMount() {
        this.generateDefaultTextForCommentField();
    }

    generateDefaultTextForCommentField = () => {
        let material = new URLSearchParams(window.location.search).get("material");
        if (material !== null) this.setState({
            _commentFieldValue: `Hallo, i want to talk about "${material}". Please call me!`,
        }); else return this.setState({
            _commentFieldValue: ``,
        })
    };
    sendEmail = async () => {
        await fetch('https://neowebmasters.com:40002/sendFeedback', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                name: this.state._userNameFieldValue,
                company: this.state._companyNameFieldValue,
                phone: this.state._phoneFieldValue,
                email: this.state._userEmailFieldValue,
                comment: this.state._commentFieldValue,
            }),
        }).then((res) =>
            this.setState({
                _isResultSent: true,
                _userNameFieldValue: "",
                _companyNameFieldValue: "",
                _userEmailFieldValue: "",
                _sendResult: "",
            })).catch(err => err);
    };

    userNameFieldHandler = (value) => {
        this.setState({
            _userNameFieldValue: value,
        });
    };
    phoneFieldHandler = (value) => {
        this.setState({
            _phoneFieldValue: value,
        });
    };
    userEmailFieldHandler = (value) => {
        this.setState({
            _userEmailFieldValue: value,
        });
    };

    companyNameFieldHandler = (value) => {
        this.setState({
            _companyNameFieldValue: value,
        });
    };

    commentFieldHandler = (value) => {
        this.setState({
            _commentFieldValue: value,
        });
    };


    render() {
        return (
            <Contacts
                phoneFieldHandler={this.phoneFieldHandler}
                commentFieldHandler={this.commentFieldHandler}
                generateDefaultTextForCommentField={this.generateDefaultTextForCommentField}
                userNameFieldValue={this.state._userNameFieldValue}
                userNameFieldHandler={this.userNameFieldHandler}
                userEmailFieldValue={this.state._userEmailFieldValue}
                userEmailFieldHandler={this.userEmailFieldHandler}
                companyNameFieldValue={this.state._companyNameFieldValue}
                phoneFieldValue={this.state._phoneFieldValue}
                commentFieldValue={this.state._commentFieldValue}
                companyNameFieldHandler={this.companyNameFieldHandler}
                sendEmail={this.sendEmail}
                language={this.props.language}
                isContactsSent={this.state._isContactsSent}
            />
        )
    }

}
